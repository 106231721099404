import { withRouter } from 'react-router-dom';
import { RedocStandalone } from 'redoc';
import KasDocsNavBar from '../../components/Navbar'

const RedocPage = (props) => {
    return (
        <>
            <KasDocsNavBar {...props} currentAPITitle={props.title} currentSDKTitle="SDK References" />
            <div style={{marginTop:"56px"}}>
                <RedocStandalone
                spec={props.spec}
                options={{
                    scrollYOffset:'.navbar',
                    nativeScrollbars: true,

                    // Use below if you want to show the path name on the left sidebar
                    // sideNavStyle:'path-only'

                    // Use below if you want to show the summary on the left sidebar
                    sideNavStyle:'summary-only'
                }}
                />
            </div>
        </>
    )
}

export default withRouter(RedocPage);
